<template>
    <div class="callback-container">
        <div class="card">
            <h2>QQ 登录成功</h2>
            <div v-if="userInfo">
                <img :src="userInfo.figureurl_qq_2" alt="用户头像" class="avatar" />
                <h3>{{ userInfo.nickname }}</h3>
                <p>用户ID: {{ userInfo.openid }}</p>
                <button @click="handleLogout">退出登录</button>
            </div>
            <div v-else>
                <p>正在获取用户信息...</p>
            </div>
        </div>
    </div>
</template>

<script>
import QC from "../utils/qqApi.js";
import { qqLogin, userInfo } from "@/api/user.js";
import { Message } from "view-design";

export default {
    data() {
        return {
            userInfo: null,
        };
    },
    created() {
    },
    mounted() {
        this.getUserInfo();
    },
    methods: {
        async getUserInfo() {
            if (QC.Login.check()) {
                QC.Login.getMe((openId, accessToken) => {
                    qqLogin({ openid: openId, accessToken }).then((res) => {
                        Message.success({
                            content: res.retVal,
                        });
                        userInfo({}).then((result) => {
                            localStorage.setItem("currentUser", JSON.stringify(result.retVal));
                            this.$router.push({ path: this.redirect || "/" });
                        });
                        localStorage.setItem("isQQLogin", true);
                    });
                });

            }
        },
        handleLogout() {
            QC.Login.signOut()
            Message.success({
                content: "您已成功退出登录！",
            });
            this.$router.push('/login');
        },
    },
};
</script>

<style scoped>
.callback-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
}

.card {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 15px;
}

h2 {
    color: #0078ff;
}

button {
    padding: 10px 20px;
    background-color: #0078ff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #005bb5;
}
</style>